import BaseBean from "@/utils/BaseBean";

export interface IJrListDataObj {
    utilInst:JrListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
}

export default class JrListUtil extends BaseBean{
    public dataObj:IJrListDataObj

    constructor(proxy:any,dataObj:IJrListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}