import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide} from 'vue';
import JrListUtil,{IJrListDataObj} from './jrListUtil';
export default defineComponent ({
    name: 'JrList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IJrListDataObj=reactive<IJrListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                isShowFixCol:false,
                queryParam: {},
                modelMethod: utils.ToolsProviderApi.buildUrl('/jr/pageData')
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new JrListUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'flag'==params.comboId){
                    return [{value:'否',label:'否'},{value:'是',label:'是'}]
                }
            }
        })

        return{
            ...toRefs(dataObj),comboSelect
        }
    }
});